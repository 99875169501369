body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App, #MainContent {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: #FDF5E6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.column-right-image-left {
  background-image: url('../public/images/split-right-left.png');
  background-repeat: no-repeat;
  background-size: 75% auto;
  background-position: left bottom;
  height: 100%;
  min-width: 100%;
}
.column-right-image-right {
  background-image: url('../public/images/split-right-right.png');
  background-repeat: no-repeat;
  background-size: 75% auto;
  background-position: top right;
  height: 100%;
  min-width: 100%;
}

.main-column {
  /* background-color: white; */
  min-height: 100%;
  padding: 0px;
}

.row {
  min-height: 100%;
}

.rsvp-outer-card {
  width: 100%;
  min-height: 100%;
  height: 100%;
  /* background-color: white; */
  /* padding-top: 180px;
  padding-bottom: 160px;
  padding-left: 110px;
  padding-right: 110px; */
  padding-top: 21%;
  padding-bottom: 18.5%;
  padding-left: 13%;
  padding-right: 13%;
  display: flex;
}

.rsvp-inner-card {
  background-color: #ffffff;
  min-height: 100%;
  min-width: 100%;
  padding: 20px;
}


.rsvp-container {
  margin: auto;
  text-align: center;
  /* min-height: 100%; */
  min-height: 100%;
  /* background-color: red; */
  padding-left: 50px;
  padding-right: 50px;
}

/* On small screens, decrease padding for container  */
@media screen and (max-width:767px){
  .rsvp-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.invitee-tab-manager {
  padding-top: 20px;
}

.inviteeForm {
  padding-top: 50px;
  padding-bottom: 20px;
}

.saveButton {
  padding: 10px 20px 10px 20px;
  border-color: #aaaaaa;
  border-radius: 0;
}

.rsvp-text {
  width: 100%;
  padding-top: 15%;
  text-align: center;
  font-family: "Inter UI";
  font-weight: bold;
  /* background-color: blue; */
}

@media screen and (min-width: 800px) {
  /* Override font size on large screens to avoid vw from causing massive text */
  .rsvp-title{
      font-size: 48pt !important;
  }

  .rsvp-subtext {
    font-size: 2.5vh !important;
    line-height: 3vw;
  }
}

/* Show tabs as 100% width on small devices */
@media screen and (max-width:767px){
  .nav-tabs > li {width:100%;}
}

.welcomeTabTitle {
  font-family: "Parisienne";
  font-weight: normal;
}

.rsvp-title {
  font-family: "Parisienne";
  font-size: 9vw;
  font-weight: normal;
}

.rsvp-subtext {
  line-height: 32px;
  font-size: 18px;
  margin-top: 10%;
}

.rsvp-form {
  margin-top: 10%;
}

.rsvp-button {
  border-radius: 0;
  padding: 15px 30px 15px 30px;
  border-color: #aaaaaa;
  font-size: 20px;
}

/* Scroll icon */
.down-arrow {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
      margin-top: 0;
  }
  50% {
      margin-top: 20px;
  }
  100% {
      margin-top: 0;
  }
}

.down-arrow {
  animation: jumpInfinite 1.5s infinite;
}
